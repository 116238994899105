











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  ApiGetPatientNameList,
  ApiGetDrugsListToChoose,
  ApiGetScaleQuestion,
  ApiSubmitScale,
  ApiGetPatientInfo,
} from "@/apis/index";
import { dosage } from "@/assets/js/dosage";
import { scale } from "@/assets/js/scaleDesc";
import { Form } from "element-ui";
import { breadcrumb } from "@/utils/breadcrumb";
import ConsultationOrder from "@/views/OnlineConsultRoom/InquiryList/index.vue";
@Component({})
export default class ScaleAssessmentAdd extends Vue {
  //基本信息表单项
  addLoading = false;
  get isCollapse() {
    return this.$store.getters.sidebar.opened;
  }
  basicInformation: any = {
    database_id: "",

    patient_name: null,
    patient_id: "",
    patient_edu: "",
    patient_relation: "",

    state_before_measure: "",
    medication_info: "",
    device_info: "", //1

    evaluate_date: "", // 2

    need_last_medication: "", // 4
    last_medication_time: "", // 3

    //本来是评估人姓名，后来取消，修改为量表名称
    evaluate_name: "",

    //选择药物列表
    last_medication: "",

    //所做问卷内容
    question_result: {
      module: "",
      label: "label",
      score_detail: "",
    },
  };
  //基本信息表单校验规则
  basicInformationRules = {
    patient_name: [
      { required: true, message: "请选择患者姓名", trigger: "blur" },
    ],
    patient_edu: [
      { required: true, message: "请选择受教育程度", trigger: "blur" },
    ],
    patient_relation: [
      { required: true, message: "请选择关系", trigger: "blur" },
    ],
    state_before_measure: [
      {
        required: true,
        message: "请选择是否需要获取患者填写量表前状态",
        trigger: "blur",
      },
    ],
    medication_info: [
      { required: true, message: "请选择药物状态", trigger: "blur" },
    ],
    device_info: [
      { required: true, message: "请选择植入设备状态", trigger: "blur" },
    ],
    evaluate_date: [
      { required: true, message: "请选择评估时间", trigger: "blur" },
    ],
    need_last_medication: [
      {
        required: true,
        message: "请选择是否需要获取患者末次药量和时间",
        trigger: "blur",
      },
    ],
  };
  //该量表所属填写说明
  scaleInstructionsList = [];
  //填写说明是否展开
  isExpanded = true;
  //点击展开或者收起填写说明
  expand() {
    this.isExpanded = !this.isExpanded;
  }
  mounted() {
    this.init();
  }
  scaleId = "";
  scaleName = "";

  cinput1 = "";
  cinput2 = "";
  dinput1 = "";
  dinput2 = "";
  dinput3 = "";
  dinput4 = "";
  dinput5 = "";
  dinput6 = "";
  dinput7 = "";
  dinput8 = "";
  dinput9 = "";
  dinput10 = "";
  dinput11 = "";
  dinput12 = "";

  initArrary = [
    { sid: 1, s: "-" },
    { sid: 2, s: "-" },
    { sid: 3, s: "-" },
    { sid: 4, s: "-" },
    { sid: 5, s: "-" },
    { sid: 6, s: "-" },
    { sid: 7, s: "-" },
    { sid: 8, s: "-" },
    { sid: 9, s: "-" },
    { sid: 10, s: "-" },
    { sid: 11, s: "-" },
    { sid: 12, s: "-" },
  ] as any; // gloabl

  tempResult = this.initArrary;
  edu = "*";
  relation = "*";
  medication = "*";
  device = "*";
  relation_show = true;
  relation_title = "知情人与患者关系：";

  // 处理回显的字符串
  solveMethod(str: any) {
    const numArr: any = str.match(/\d\D+/g);
    // const arr = [...numArr];
    const arr = numArr.map((item: any, index: any) => {
      if (index == numArr.length - 1) {
        return item.slice(0, 1);
      } else {
        return item;
      }
    });
    return arr.join("");
  }

  // 数据回显
  getpatient() {
    this.getDrugsListToChoose();
    localStorage.setItem("activeName", "fourth");
    localStorage.setItem("tabIndex", "3");
    ApiGetPatientInfo({ database_id: this.$route.query.database_id }).then(
      (res) => {
        Object.keys(this.basicInformation).forEach((item) => {
          this.basicInformation[item] = res.data[item] ? res.data[item] : "";
        });
        this.basicInformation.patient_name = localStorage.getItem("user_name");
        const date = new Date().getTime();
        this.basicInformation.evaluate_date = date;
        // Object.keys(this.basicInformation).forEach((item) => {
        //   this.basicInformation[item] = null ? "" : this.basicInformation[item];
        // })
        for (const key in this.basicInformation)
          this.basicInformation[key] != null ? this.basicInformation[key] : "";

        (this.basicInformation.question_result = {
          module: "",
          label: "",
          score_detail: "",
        }),
          (this.basicInformation.database_id = this.$route.query.database_id);
        // console.log(this.basicInformation, "2323");
        this.drugsResultList = JSON.parse(
          this.basicInformation.last_medication
        );
        this.drugsResultList.forEach((item: any, index: any) => {
          this.drugsListToChoose.find((items: any) => {
            if (items.name == item.name) {
              this.drugsResultList[index].unit = items.unit;
            }
          });
        });
        this.drugsResultList = this.drugsResultList.map((item) => {
          return { ...item, dosage: this.solveMethod(item.dosage) };
        });
      }
    );
  }
  init() {
    if (this.$route.query.database_id) {
      this.getpatient();
      this.getDrugsListToChoose();
    }
    this.getPatientNameList();
    this.getDrugsListToChoose();

    //获取量表评估列表页传值内容
    this.scaleId = this.$route.query.scaleId as string;
    this.scaleName = this.$route.query.scaleName as string;
    this.getScaleQuestion();

    if (this.scaleId != "222" && this.scaleId != "221") {
      this.basicInformationRules["patient_edu"] = [];
      this.edu = "";
    }

    // MDS-UPDRS-第一部分A、MDS-UPDRS-第一部分B、MDS-UPDRS-第二部分、
    if (this.scaleId == "212") {
      this.relation_title = "原始数据来源是：";
      this.relationshipTypeList = [
        {
          name: "患者",
        },
        {
          name: "照料者",
        },
        {
          name: "双方",
        },
      ];
    }
    if (this.scaleId == "213" || this.scaleId == "214") {
      this.relation_title = "本问卷填写者（请勾选最适切的答案）：";
      this.relationshipTypeList = [
        {
          name: "患者",
        },
        {
          name: "照料者",
        },
        {
          name: "患者和照料者",
        },
      ];
    }
    // MDS-UPDRS-第三部分 、 updrs-3量表（本次新增）：删除“知情人与患者的关系”
    if (this.scaleId == "215" || this.scaleId == "257") {
      this.basicInformationRules["patient_relation"] = [];
      this.relation_show = false;
    }
    // 汉密尔顿焦虑、汉密尔顿抑郁、帕金森患者生活质量问卷、社会功能缺陷量表、日常生活能力量表：“知情人与患者的关系”，改为非必填
    if (
      this.scaleId == "223" ||
      this.scaleId == "224" ||
      this.scaleId == "226" ||
      this.scaleId == "258" ||
      this.scaleId == "259"
    ) {
      this.basicInformationRules["patient_relation"] = [];
      this.relation = "";
    }
    // 除 updrs-3量表（本次新增）和MDS-UPDRS-第三部分外，其他量表此项均为非必填项
    if (this.scaleId != "257" && this.scaleId != "215") {
      this.basicInformationRules["medication_info"] = [];
      this.basicInformationRules["device_info"] = [];
      this.medication = "";
      this.device = "";
    }

    //获取量表所属填写说明
    this.scaleInstructionsList = (scale as any)[this.scaleId];
    //初始化评估时间
    this.initEvaluationTime();

    if (!this.isCollapse) {
      (document as any).querySelector(".footer").classList.remove("collapsed");
    } else {
      (document as any).querySelector(".footer").classList.add("collapsed");
    }
  }
  //患者姓名下拉框数据
  patientNameList: any[] = [];
  //获取患者姓名下拉框数据
  getPatientNameList() {
    const param = {
      user_name: "",
    };
    ApiGetPatientNameList(param).then((res) => {
      if (res.success) {
        this.patientNameList = res.data;
      }
    });
  }

  //受教育程度下拉框数据
  educationLevelList = [
    {
      name: "未知",
    },
    {
      name: "本科",
    },
    {
      name: "研究生",
    },
    {
      name: "大专",
    },
    {
      name: "中专",
    },
    {
      name: "技校",
    },
    {
      name: "高中",
    },
    {
      name: "初中",
    },
    {
      name: "小学",
    },
    {
      name: "文盲",
    },
  ];
  //知情人与患者关系下拉框数据
  relationshipTypeList = [
    {
      name: "患者本人",
    },
    {
      name: "照料者",
    },
    {
      name: "患者和照料者",
    },
  ];
  //格式化空数据
  dash(data: any) {
    if (data == null || data == "") {
      data = "-";
    }
    return data;
  }
  formatDate(timeStamp: number | string | null) {
    if (timeStamp == null) {
      return "-";
    }
    const time = new Date(timeStamp);
    //年-月-日
    return `${time.getFullYear()}-${this.format(
      time.getMonth() + 1
    )}-${this.format(time.getDate())} ${this.format(
      time.getHours()
    )}:${this.format(time.getMinutes())}:${this.format(time.getSeconds())}`;
  }
  format(date: number | string) {
    date = date.toString();
    return ("0" + date).slice(-2);
  }

  //基础信息模块-- -- --
  //当前用户的id
  currentUserID: null | number | string = null;
  //获取量表id，为选择用户后带出的用户id
  changeUserChoose(val: any) {
    this.currentUserID = this.$route.query.database_id || val.user_id;
    this.basicInformation.patient_name = val.user_name;
    // this.patientNameList.find((item: any) => {
    //   return item.user_name == name;
    // }).user_id;
  }
  //控制 药物状态 植入设备状态 显示
  get controlPartOne() {
    return this.basicInformation.state_before_measure == "需要";
  }
  //控制 是否需要 获取患者末次药量和时间 显示
  get controlPartTwo() {
    return (
      // this.basicInformation.state_before_measure == "需要" &&
      this.basicInformation.medication_info == "开" ||
      this.basicInformation.medication_info == "关"
      // this.basicInformation.medication_info == "不吃任何药物" ||
      // this.basicInformation.medication_info == "不清楚"
    );
  }
  //控制末次服药时间和选择药物显示
  get controlPartThree() {
    return (
      this.basicInformation.need_last_medication == "需要" &&
      (this.basicInformation.medication_info == "开" ||
        this.basicInformation.medication_info == "关")
    );
  }
  //初始化评估时间
  initEvaluationTime() {
    const date = new Date().getTime();
    this.basicInformation.evaluate_date = date;
  }

  //选择药物模块-- -- --

  //查询的药物名称
  drugName = "";
  //可选药物列表
  drugsListToChoose = [];
  //选择药物对话框是否显示
  formDialogVisible = false;
  //选择药物对话框已选择药物列表--确定前
  selectedDrugsList = [];
  //选择结束用于展示的列表
  drugsResultList: Array<any> = [];
  //选择服药用量的下拉框数据
  medicinePill = dosage;
  //获取可选药物列表
  getDrugsListToChoose() {
    const param = {
      name: this.drugName,
    };
    ApiGetDrugsListToChoose(param).then((res) => {
      if (res.success) {
        this.drugsListToChoose = res.data;
        // id: 1
        // name: "美多芭（多巴丝肼片）"
        // unit: "片"
      }
    });
  }
  //点击选择药物按钮触发的函数
  clickChoose() {
    this.formDialogVisible = true;
    this.$nextTick(() => {
      const myTable: any = this.$refs["multipleTable"];
      //如果是第一次代开对话框，则没有对话框里的表格实例
      if (myTable) {
        //先把对话框内表格选中框清零
        myTable.clearSelection();
        if (this.drugsResultList.length != 0) {
          const table = this.drugsResultList.map((item: any) => {
            return {
              name: item.name,
            };
          });
          for (const i of table) {
            const index: any = this.findIndex(this.drugsListToChoose, i);
            // console.log(i);
            // console.log(index);
            myTable.toggleRowSelection(myTable.data[index]);
          }
        }
      }
    });
  }
  findIndex(arr: any, item: any) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].name === item.name) {
        return i;
      }
    }
  }
  //药物选择对话框-点击添加按钮触发的函数
  completeFormConfirm() {
    /**
     * 分两个阶段处理，先把下面没用的删掉
     * 再把下面没有的推入
     */
    const choosedList = this.selectedDrugsList;
    const resultList = this.drugsResultList;
    let choosedNameList: Array<any> = [];
    let resultNameList: Array<any> = [];
    choosedNameList = choosedList.map((item: any) => {
      return item.name;
    });
    resultNameList = resultList.map((item: any) => {
      return item.name;
    });
    //第一阶段
    //先找到结果列表里有，但是新选择列表里没有的，把结果列表中的删掉
    const extraResult = resultNameList.filter((item) => {
      return choosedNameList.indexOf(item) === -1;
    });
    // console.log(extraResult);
    for (let index = 0; index < extraResult.length; index++) {
      const name = extraResult[index];
      const resultIndex = resultNameList.indexOf(name);
      this.drugsResultList.splice(resultIndex, 1);
    }
    //第二阶段
    //然后找到新选择列表里有，结果列表里没有的，把新的数据添加到结果列表中
    const extraChoosed = choosedNameList.filter((item) => {
      return resultNameList.indexOf(item) === -1;
    });
    // console.log(extraChoosed);
    for (let index = 0; index < extraChoosed.length; index++) {
      const name = extraChoosed[index];
      const resultIndex = choosedNameList.indexOf(name);
      const resultItem: any = choosedList[resultIndex];
      this.drugsResultList.push({
        name: resultItem.name,
        dosage: "",
        id: resultItem.id,
        unit: resultItem.unit,
      });
    }
    //关闭对话框
    this.closeFormDialog();
  }
  //药物选择对话框-关闭选择药物对话框触发的函数
  closeFormDialog() {
    this.formDialogVisible = false;
  }
  //选择药物表格数据变化时触发的函数
  handleSelectionChange(row: any) {
    //用于存储对话框选中的表格行
    this.selectedDrugsList = row;
  }
  //点击表格某一行触发函数
  clickRow(row: any, column: any, event: any) {
    const myTable: any = this.$refs["multipleTable"];
    myTable.toggleRowSelection(row);
  }
  //所服药物表格选择框选择变化时触发
  chooseCount(data: any) {
    const item: any = this.drugsResultList.find((i) => {
      return i === data;
    });
    const index = this.getArrayIndex(this.drugsResultList, item);
    this.drugsResultList.splice(index, 1, data as never);
  }
  getArrayIndex(arr: any, obj: any) {
    let i = arr.length;
    while (i--) {
      if (arr[i] === obj) {
        return i;
      }
    }
    return -1;
  }
  //在已选择药物表格里点击删除按钮触发的函数
  deleteDrug(row: any) {
    this.drugsResultList = this.drugsResultList.filter((item) => {
      return item != row;
    });
    // console.log(this.drugsResultList);
  }

  //量表问卷模块-- -- --

  //该量表所属问卷内容
  scaleQuestionList: any[] = [];
  //获取该量表所属问卷内容
  getScaleQuestion() {
    console.log("------------------获取问卷题目");
    const param = {
      module: this.scaleId,
    };
    ApiGetScaleQuestion(param).then((res) => {
      if (res.success) {
        this.scaleQuestionList = res.data;
        // console.log(this.scaleQuestionList, "我是scaleQuestionList");
        this.scaleQuestionList.map((item: any) => {
          // console.log(item.question_type);
          //添加的第一个属性：问题标题列表
          item.myQuestionTitleList = item.question.split("\r\n");
          //截去标题标号,使用自己的标号
          const firstTitle = item.myQuestionTitleList[0];
          // console.log(firstTitle);
          const myTitle = firstTitle.replace(/(^\w*.)|(^\d*.)/, "");
          // console.log(myTitle);
          // console.log(myTitle.startsWith('氏与叶氏（Hoehn & Yahr）分期法'));
          if (myTitle.startsWith("氏与叶氏（Hoehn & Yahr）分期法")) {
            item.myQuestionTitleList[0] = "侯" + myTitle;
          } else {
            item.myQuestionTitleList[0] = myTitle;
          }
          const module = item.module;
          if (module == 221) {
            item.myQuestionTitleList[1] = item.myQuestionTitleList[1].replace(
              /(^\w*.)|(^\d*.)/,
              ""
            );
          }
          //添加的第二个属性：
          item.myChoiceList = JSON.parse(item.choice_item);
          /**
           * 102题型
           * 标题都在question属性中，整体是一个字符串，标题之间用\r\n分开
           * 拆开字符串后增加属性myQuestionTitleList
           * 选项都在choice_item属性中，整体是一个字符串（数组），解析出后
           * 增加属性myChoiceList
           * 注意：102题型所有标题拥有的选项都是一样的
           *
           * 103||104题型 一个题中包含多个小题
           *
           *
           */
        });
      }
    });
  }

  //量表提交模块-- -- --

  //作答结果列表
  answerList: any = [];
  //普通类型量表单选点击触发函数
  changeQuestionRadio(
    type: number,
    question: any,
    questionIndex: number,
    choiceIndex: number,
    secondaryQuestionIndex: number
  ) {
    /**
     * 该函数主要做了三件事：
     * 一、触发视图更新
     * 二、如果之前是未选中状态，点击之后选中，则需要添加到作答列表中
     * 如果作答列表中之前有这个题，则需要替换
     * 三、如果之前是选中状态，点击需要取消选中，
     */
    this.removeAnswer(question.sid);
    //给嵌套函数使用组件vue全局对象
    const self = this;
    // console.log(question);
    //点击普通单选
    function clickCommonRadio() {
      //拿到该题当前选中内容，第一次选中时为undefined,之后为''或者'分'
      const questionResult = question.myScoreResult;
      //拿到当前点击单选项的分数
      const choiceScore = question.myChoiceList[choiceIndex].score;
      const choiceName = question.myChoiceList[choiceIndex].choice_name;
      // console.log(questionResult);
      // console.log(choiceScore);
      if (questionResult === choiceName) {
        (self.scaleQuestionList[questionIndex] as any).myScoreResult = "";
      } else {
        //questionResult为undefinde 第一次选中
        //questionResult为'' 之前取消了
        //questionResult为'分'之前已有选中
        (self.scaleQuestionList[questionIndex] as any).myScoreResult =
          choiceName;
        self.pushAnswer(
          type,
          question,
          questionIndex,
          choiceIndex,
          secondaryQuestionIndex
        );
      }
    }
    //点击并排单选
    function clickRowRadio() {
      //拿到该题当前选中内容，第一次选中时为undefined,之后为''或者'分'
      const questionResult =
        question.myChoiceList[secondaryQuestionIndex].myScoreResult;
      //拿到当前点击单选项的分数
      const choiceName =
        question.myChoiceList[secondaryQuestionIndex].items[choiceIndex]
          .choice_name;
      // console.log(questionResult);
      // console.log(choiceName);
      if (questionResult == choiceName) {
        (self.scaleQuestionList[questionIndex] as any).myChoiceList[
          secondaryQuestionIndex
        ].myScoreResult = "";
      } else {
        //questionResult为undefinde 第一次选中
        //questionResult为'' 之前取消了
        //questionResult为'分'之前已有选中
        (self.scaleQuestionList[questionIndex] as any).myChoiceList[
          secondaryQuestionIndex
        ].myScoreResult = choiceName;
        self.pushAnswer(
          type,
          question,
          questionIndex,
          choiceIndex,
          secondaryQuestionIndex
        );
      }
    }
    //点击嵌套单选
    function clickSpeRadio() {
      //拿到该题当前选中内容，第一次选中时为undefined,之后为''或者'分'
      const questionResult =
        question.myChoiceList[secondaryQuestionIndex].myScoreResult;
      //拿到当前点击单选项的分数
      const choiceScore =
        question.myChoiceList[secondaryQuestionIndex].items[choiceIndex].score;
      const choiceName =
        question.myChoiceList[secondaryQuestionIndex].items[choiceIndex]
          .choice_name;
      if (questionResult === choiceName) {
        (self.scaleQuestionList[questionIndex] as any).myChoiceList[
          secondaryQuestionIndex
        ].myScoreResult = "";
        //在取消完该小题选中后，如果本题有其他小题还有答案，还是需要再添加进作答列表
        const list = question.myChoiceList;
        for (let index = 0; index < list.length; index++) {
          const item = list[index];
          if (item.myScoreResult) {
            self.pushAnswer(
              type,
              question,
              questionIndex,
              choiceIndex,
              secondaryQuestionIndex
            );
            break;
          }
        }
      } else {
        //questionResult为undefinde 第一次选中
        //questionResult为'' 之前取消了
        //questionResult为'分'之前已有选中
        (self.scaleQuestionList[questionIndex] as any).myChoiceList[
          secondaryQuestionIndex
        ].myScoreResult = choiceName;
        self.pushAnswer(
          type,
          question,
          questionIndex,
          choiceIndex,
          secondaryQuestionIndex
        );
      }
    }

    //非嵌套处理
    if (type == 0) {
      clickCommonRadio();
      //嵌套处理
    } else if (type == 1) {
      clickSpeRadio();
    } else if (type == 2) {
      clickRowRadio();
    }
    this.scaleQuestionList = [...self.scaleQuestionList];
  }
  //添加答案
  pushAnswer(
    type: number,
    question: any,
    questionIndex: number,
    choiceIndex: number,
    secondaryQuestionIndex: number
  ) {
    const questionType = question.question_type;
    //sid其实是第几题，从1开始的，要是用的话需要减一
    const questionId = question.sid;
    // console.log(questionType,questionId);
    const answerItem: any = {};
    //处理普通单选
    function formatCommonRadio() {
      //找到名字对应的选项分数
      const resultItem = question.myChoiceList.find((item: any) => {
        return item.choice_name == question.myScoreResult;
      });
      const scoreResult = resultItem.score;
      answerItem.question_type = questionType;
      answerItem.s = [
        {
          choice_index: [choiceIndex],
          score: scoreResult,
        },
      ];
      answerItem.sid = questionId;
    }
    //处理并排单选--提供s
    function getRowSecondAnswer() {
      const scoreResult = question.myChoiceList[0].myScoreResult;
      const index = scoreResult == "是" ? 0 : 1;
      const result = [];
      result.push({
        choice_index: [0, index],
        score: -1,
      });
      return result;
    }
    //处理并排单选
    function formatRowRadio() {
      answerItem.question_type = questionType;
      answerItem.s = getRowSecondAnswer();
      answerItem.sid = questionId;
    }
    //处理嵌套单选--提供s
    function getSpeSecondAnswer() {
      const result = [];
      const list = question.myChoiceList;
      for (let index = 0; index < list.length; index++) {
        const item = list[index];
        // console.log(item.myScoreResult);
        if (item.myScoreResult !== undefined && item.myScoreResult !== "") {
          const itemsList = item.items;
          const resultItem = itemsList.find((secondyItem: any) => {
            return secondyItem.choice_name == item.myScoreResult;
          });
          const scoreResult = resultItem.score;
          result.push({
            choice_index: [index, scoreResult],
            score: scoreResult,
          });
        }
      }
      return result;
    }
    //处理嵌套单选
    function formatSpeRadio() {
      answerItem.question_type = questionType;
      answerItem.s = getSpeSecondAnswer();
      answerItem.sid = questionId;
    }

    switch (type) {
      //普通单选
      case 0: {
        formatCommonRadio();
        break;
      }
      //嵌套单选
      case 1: {
        formatSpeRadio();
        break;
      }
      //并排单选
      case 2: {
        formatRowRadio();
        break;
      }
    }
    // console.log("这里是这一项答案", answerItem);
    this.answerList.push(answerItem);
    // console.log("这里是答案结果列表", this.answerList);
    //推进答案前，先把结果列表中该题之前的答案清空
  }
  //移除答案
  removeAnswer(id: number) {
    this.answerList = this.answerList.filter((item: any) => {
      return item.sid != id;
    });
    // console.log("清除该题", this.answerList);
  }

  inputBlurChange(module: number, sid: any, myinput: any) {
    const params = {
      module: module,
      sid: sid,
      s: myinput,
    };
    // if have val : splice(idx,1,newVal);
    //  push

    for (const index in this.tempResult) {
      if (this.tempResult[index].sid == sid) {
        this.tempResult[index] = params;
        return;
      }
    }
    this.tempResult.push(params);
  }

  //底部按钮模块 -- -- --
  back() {
    this.$router.go(-1);
  }
  resetProperty(property: any, obj: any) {
    const type = typeof property;
    if (type == "string") {
      obj[property] = "";
    } else if (type == "object") {
      for (const item of property) {
        obj[item] = "";
      }
    }
  }
  confirm() {
    const formRef = this.$refs.basicInformationRef as Form;
    formRef.validate((valid: boolean) => {
      if (valid) {
        const drugsResultList = this.drugsResultList;
        let showWarning = false;
        for (let index = 0; index < drugsResultList.length; index++) {
          const item: any = drugsResultList[index];
          if (item.dosage == "") {
            showWarning = true;
          }
        }
        if (showWarning) {
          this.$message.warning("请填写末次所服药物的服用药量！");
          return;
        }

        //处理药物列表--要在处理基础信息之前，因为基础信息可能把它清掉
        // console.log(this.drugsResultList);
        if (this.drugsResultList.length == 0) {
          this.basicInformation.last_medication = JSON.stringify([]);
        } else {
          // console.log(this.drugsResultList, "this.drugsResultList");
          const result = this.drugsResultList.map((item: any) => {
            return {
              dosage: `一次${item.dosage}${item.unit}`,
              name: item.name,
            };
          });
          this.basicInformation.last_medication = JSON.stringify(result);
        }
        // console.log(this.basicInformation.last_medication);

        //处理基础信息
        this.basicInformation.database_id = this.currentUserID as string;
        this.basicInformation.evaluate_name = this.scaleName;
        const basic = this.basicInformation;
        // if (basic.state_before_measure != "需要") {
        //   this.resetProperty(
        //     [
        //       "medication_info",
        //       "device_info",
        //       "need_last_medication",
        //       "last_medication_time",
        //       "last_medication",
        //     ],
        //     this.basicInformation
        //   );
        // }
        if (
          basic.medication_info == "不清楚" ||
          basic.medication_info == "不吃任何药物"
        ) {
          this.resetProperty(
            ["need_last_medication", "last_medication_time", "last_medication"],
            this.basicInformation
          );
        }
        if (basic.need_last_medication != "需要") {
          this.resetProperty(
            ["last_medication_time", "last_medication"],
            this.basicInformation
          );
        }

        //处理作答列表
        if (this.answerList.length == 0) {
          this.basicInformation.question_result.score_detail = JSON.stringify(
            []
          );
        } else {
          this.basicInformation.question_result.score_detail = JSON.stringify(
            this.answerList
          );
        }
        this.basicInformation.question_result.module =
          this.scaleQuestionList[0].module;

        const param = {
          ...this.basicInformation,
        };
        if (!param.database_id) {
          param.database_id = this.$route.query.database_id;
        }

        if (
          this.scaleQuestionList[0] &&
          (this.scaleQuestionList[0].module == 248 ||
            this.scaleQuestionList[0].module == 249)
        ) {
          this.tempResult.sort((a: any, b: any) => a.sid - b.sid);
          console.log(JSON.stringify(this.tempResult));
          this.basicInformation.question_result.score_detail = JSON.stringify(
            this.tempResult
          );
          console.log(param);
          this.tempResult = this.initArrary;
        }
        this.addLoading = true;
        // console.log(param, "param");
        ApiSubmitScale(param).then((res) => {
          if (res.success) {
            this.$message.success("新增成功！");
            this.addLoading = false;
            this.back();
            // breadcrumb.push(
            //   this,
            //   "/doctor-system/elemedirecord/scaleAssessment",
            //   "量表评估",
            //   null,
            //   false
            // );
          }
        });
      }
    });
  }
}
